import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    EuiFlexGroup,
    EuiFlexItem,
    EuiForm,
    EuiLoadingContent,
    EuiCard,
    EuiSpacer,
    EuiHorizontalRule,
    EuiPanel,
} from '@elastic/eui';
import { useForm, FormProvider } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import * as types from '../../../../../reduxStore/types/menu';
import { isEmpty, isArray } from 'lodash';
import API from '../../../../../api/axios/API';
import ItemPricing from './ItemPricing';
import ItemExpose from './ItemExpose';
import {
    multipleTimings,
    timeSlotData,
} from '../../../../../components/menuComponentsRefactorCopy/menuEditorTab/utils';
import ItemBadges from '../../../../../components/menuComponentsRefactorCopy/menuEditorTab/ItemBadges';
import {
    SET_BADGES_IDS,
    SET_SAVED_VARIANT_GROUP,
    UNLINK_BADGES_IDS,
    SET_CATALOGUE_ITEM_LIST,
} from '../../../../../components/menuComponentsRefactorCopy/Store/MenuTypes';
import MappedItemModal from '../../../../../components/menuComponentsRefactorCopy/menuEditorTab/MappedItemModal';
import ItemBasicDetails from './ItemBasicDetails';
import ItemAddonGroups from './ItemAddonGroups';
import ItemImage from './ItemImage';
import ItemTimings from './ItemTimings';
import ItemUpSellingLinks from '../../ItemUpSellingLinks';
import { fetchCategoryItem } from '../../../../../components/menuComponentsRefactorCopy/catalogue/Api/FetchCatalogueInfo';
import CataloguePanelHeader from '../CataloguePanelHeader';
import ItemFormErrors from './ItemFormErrors';
import { toastsErrorMessage, toastSuccessMessage } from '../../../../../utils/toasts';
import ItemDetailsActions from './ItemDetailsActions';
import useSingleScreenHeightCalc from '../../../../../hooks/useSingleScreenHeightCalc';
import ItemUpsellCart from './ItemUpsellCart';
import ItemBestSellCategory from './BestSellerCategoryItem';

const ItemDetailsEditor = () => {
    const badges_ids = useSelector((state) => state.menuReducer.badgesIds);
    const unlink_badges_ids = useSelector((state) => state.menuReducer.unLinkBadgesIds);
    const categories = useSelector((state) => state.menuReducer.restaurantCategories);
    const availableLanguages = useSelector((state) => state.language.availableLanguages);
    const editItem = useSelector((state) => state.menuReducer.activeItemDetails.details);
    const activeItemId = useSelector((state) => state.menu.activeItemId);
    const selectedItemEditAddOnList = useSelector((state) => state.menu.selectedItemEditAddOnList);
    const selectedItemEditUpsellingList = useSelector((state) => state.menu.selectedItemEditUpsellingList);
    const removedItemEditUpsellingList = useSelector((state) => state.menu.removedItemEditUpsellingList);
    const activeCategoryItemList = useSelector((state) => state.menu.activeCategoryItemList);
    const languageId = useSelector((state) => state.language.languageId);
    const variantGroupReducer = useSelector((state) => state.menu.variantGroup);
    const variantGroup = useSelector((state) => state.menu.variantGroup);
    const activeCategoryId = useSelector((state) => state.menu.activeCategoryId);
    const variantModalSaved = useSelector((state) => state.menu.variantModalSaved);
    const variantAddonLink = useSelector((state) => state.menu.variantAddonLink);
    const variantGroupTranslations = useSelector((state) => state.menu.variantGroupTranslations);
    const mainSubmitClicked = useRef(false);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const orderingModes = useSelector((state) => state.menuReducer.restaurantOrderingModes);
    const isItemDetailsLoading = useSelector((state) => state.menuReducer.activeItemDetails.isLoading);
    const loadingArray = Array.from({ length: 5 }, () => Math.floor(Math.random() * (5 - 2 + 1)) + 2);
    const [mapItemModal, setIsMapItemModal] = useState(false);
    const isOutletMenu = useSelector((state) => state.menuReducer.isOutletMenu);
    const methods = useForm({});
    const { handleSubmit, reset } = methods;
    const { screenHeight } = useSingleScreenHeightCalc(400);

    useEffect(() => {
        if (categories && categories.categories && categories.categories.length > 0 && !activeItemId) {
            dispatch({
                type: types.EDIT_ITEM,
                payload: {
                    editItem:
                        categories.categories[0].items !== undefined && categories.categories[0].items.length > 0
                            ? categories.categories[0].items[0]
                            : {},
                    origin: 'itemDetails',
                },
            });

            if (
                categories.categories[0].items &&
                categories.categories[0].items.length > 0 &&
                categories.categories[0].items[0].addon_groups !== undefined &&
                categories.categories[0].items[0].addon_groups.length > 0
            ) {
                dispatch({
                    type: types.SET_ADDON_ITEM_EDIT_SELECTED_LIST,
                    payload: {
                        selectedItemEditAddOnList: categories.categories[0].items[0].addon_groups,
                    },
                });
            }
        }
    }, [categories]);

    useEffect(() => {
        let id = '';
        let editItem = {};
        let editItemAddon = [];
        activeCategoryItemList.forEach((item) => {
            if (item.item_id === activeItemId) {
                id = item.item_id;
                editItem = item;
                editItemAddon = item.addon_groups && item.addon_groups.length > 0 ? item.addon_groups : [];
            }
        });

        if (!id && activeCategoryItemList && activeCategoryItemList.length > 0) {
            id = activeCategoryItemList[0].item_id;
            editItem = activeCategoryItemList[0];
            editItemAddon =
                activeCategoryItemList[0].addon_groups && activeCategoryItemList[0].addon_groups.length > 0
                    ? activeCategoryItemList[0].addon_groups
                    : [];
        }

        dispatch({
            type: types.EDIT_ITEM,
            payload: {
                editItem: editItem,
            },
        });

        dispatch({
            type: types.SET_ADDON_ITEM_EDIT_SELECTED_LIST,
            payload: {
                selectedItemEditAddOnList: editItemAddon,
            },
        });
    }, [activeCategoryItemList]);

    useEffect(() => {
        if (!isEmpty(editItem) && editItem.item_id !== 'newId' && activeItemId != 'newId') {
            let editDefaultValue = { ...editItem };

            availableLanguages.forEach((language) => {
                editDefaultValue[`title${language.language_id}`] =
                    editDefaultValue.translations?.title[language.language_id];
                editDefaultValue[`description${language.language_id}`] =
                    editDefaultValue.translations?.description[language.language_id];
            });
            // const same_schedules = editItem?.ordering_modes?.[0]?.
            let formatedSscheduleMap = {};
            if (editDefaultValue.time_slot_setting !== 'no_time_slots' && isArray(editDefaultValue.schedule)) {
                for (let scheduleElement of editDefaultValue.schedule) {
                    if (formatedSscheduleMap.hasOwnProperty(scheduleElement.time_slots.day_of_week)) {
                        formatedSscheduleMap[scheduleElement.time_slots.day_of_week].push({
                            start_time: scheduleElement.time_slots.start_time,
                            end_time: scheduleElement.time_slots.end_time,
                        });
                    } else {
                        formatedSscheduleMap[scheduleElement.time_slots.day_of_week] = [
                            {
                                start_time: scheduleElement.time_slots.start_time,
                                end_time: scheduleElement.time_slots.end_time,
                            },
                        ];
                    }
                }
            }

            // timeslots for multiple ordering modes
            // `timer_for_item_${orderingMode.restaurant_ordering_mode_id}_${orderingMode.restaurant_ordering_mode_name}`;
            if (editItem?.ordering_modes?.length) {
                for (const element of editItem.ordering_modes) {
                    let diffFormatedScheduleMap = {};
                    if (element.time_slot_setting !== 'no_time_slots' && isArray(element.schedules)) {
                        for (let scheduleElement of element.schedules) {
                            if (diffFormatedScheduleMap.hasOwnProperty(scheduleElement.day_of_week)) {
                                diffFormatedScheduleMap[scheduleElement.day_of_week].push({
                                    start_time: scheduleElement.start_time,
                                    end_time: scheduleElement.end_time,
                                });
                            } else {
                                diffFormatedScheduleMap[scheduleElement.day_of_week] = [
                                    {
                                        start_time: scheduleElement.start_time,
                                        end_time: scheduleElement.end_time,
                                    },
                                ];
                            }
                        }
                    }

                    editDefaultValue.week = {
                        ...editDefaultValue.week,
                        [`sunday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]: [],
                        [`monday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]: [],
                        [`tuesday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]: [],
                        [`wednesday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                            [],
                        [`thursday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                            [],
                        [`friday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]: [],
                        [`saturday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                            [],
                    };

                    if (element.time_slot_setting === 'same_time_slot_for_all_days') {
                        editDefaultValue[
                            `time_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                        ] = diffFormatedScheduleMap['1'];
                        editDefaultValue[
                            `timer_for_item_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                        ] = `same_time_for_all_days_${element.restaurant_ordering_mode_id}`;
                    } else if (element.time_slot_setting === 'different_time_slots_for_different_days') {
                        editDefaultValue.week = {
                            ...editDefaultValue.week,
                            [`sunday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                                [],
                            [`monday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                                [],
                            [`tuesday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                                [],
                            [`wednesday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                                [],
                            [`thursday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                                [],
                            [`friday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                                [],
                            [`saturday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`]:
                                [],
                        };
                        for (const key in diffFormatedScheduleMap) {
                            if (key == 7) {
                                editDefaultValue.week[
                                    `sunday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                                ] = diffFormatedScheduleMap[key];
                            }
                            if (key == 1) {
                                editDefaultValue.week[
                                    `monday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                                ] = diffFormatedScheduleMap[key];
                            }
                            if (key == 2) {
                                editDefaultValue.week[
                                    `tuesday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                                ] = diffFormatedScheduleMap[key];
                            }
                            if (key == 3) {
                                editDefaultValue.week[
                                    `wednesday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                                ] = diffFormatedScheduleMap[key];
                            }
                            if (key == 4) {
                                editDefaultValue.week[
                                    `thursday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                                ] = diffFormatedScheduleMap[key];
                            }
                            if (key == 5) {
                                editDefaultValue.week[
                                    `friday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                                ] = diffFormatedScheduleMap[key];
                            }
                            if (key == 6) {
                                editDefaultValue.week[
                                    `saturday_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                                ] = diffFormatedScheduleMap[key];
                            }
                        }
                        editDefaultValue[
                            `timer_for_item_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                        ] = `different_time_for_different_days_${element.restaurant_ordering_mode_id}`;
                    } else {
                        editDefaultValue[
                            `timer_for_item_${element.restaurant_ordering_mode_id}_${element.restaurant_ordering_mode_name}`
                        ] = `same_day_of_the_week_${element.restaurant_ordering_mode_id}`;
                    }
                }
            }

            let variantOptionArray = [];

            if (editItem.variants && editItem.variants.length) {
                // let innerArray = []

                editDefaultValue['variant_group_id'] = editItem.variants[0].id;
                editDefaultValue['variant_group_title'] = editItem.variants[0].translations.title[languageId];
                editDefaultValue['variant_group_description'] =
                    editItem.variants[0].translations.description[languageId];
                editDefaultValue['show_agmark'] = editItem.variants[0].show_agmark ? 1 : 0;

                for (let key in editItem.variants[0].translations.title) {
                    editDefaultValue[`variant_group_title-${key}`] = editItem.variants[0].translations.title[key];
                }

                for (let key in editItem.variants[0].translations.description) {
                    editDefaultValue[`variant_group_description-${key}`] =
                        editItem.variants[0].translations.description[key];
                }

                editItem.variants[0].options.forEach((option) => {
                    let innerObj = {
                        compare_price: option.compare_price,
                        price: option.price,
                        packaging_charge: option.packaging_charge,
                        gst_slab: option.gst_slab,
                        food_type: option.food_type,
                        image_url: option.image_url,
                        // id: option.id
                    };

                    for (let key in option.translations.title) {
                        innerObj[`variant_title-${key}`] = option.translations.title[key];
                    }

                    variantOptionArray.push(innerObj);
                });
            }
            let isEnabled = [];

            if (editItem.ordering_modes?.length) {
                editItem.ordering_modes.forEach((orderingMode) => {
                    orderingMode[`price_${orderingMode.restaurant_ordering_mode_id}`] = orderingMode.price;
                    orderingMode[`gst_slab_${orderingMode.restaurant_ordering_mode_id}`] = orderingMode.gst_slab
                        ? orderingMode.gst_slab
                        : 0;
                    orderingMode[`packaging_charge_${orderingMode.restaurant_ordering_mode_id}`] =
                        orderingMode.packaging_charge ? orderingMode.packaging_charge : 0;
                    orderingMode[`compare_price_${orderingMode.restaurant_ordering_mode_id}`] =
                        orderingMode.compare_price ? orderingMode.compare_price : 0;
                    orderingMode.allergens = orderingMode?.allergens?.filter(
                        (allergen) => (allergen.label = allergen.internal_name)
                    );
                });

                const same_price = editItem.ordering_modes[0].price;
                const same_compare_price = editItem.ordering_modes[0].compare_price;
                const same_packaging = editItem.ordering_modes[0].packaging_charge;
                const same_gst_slab = editItem.ordering_modes[0].gst_slab;
                const same_serves = editItem.ordering_modes[0].serves;
                const same_quantity_unit_id = editItem.ordering_modes[0].quantity_unit_id;
                const same_calories = editItem.ordering_modes[0].same_calories;

                for (let index = 0; index < editItem.ordering_modes.length; index++) {
                    const element = editItem.ordering_modes[index];
                    if (
                        same_price == element.price &&
                        same_packaging == element.packaging_charge &&
                        same_gst_slab == element.gst_slab &&
                        same_compare_price == element.compare_price &&
                        same_serves == element.serves &&
                        same_quantity_unit_id == element.quantity_unit_id &&
                        same_calories == element.same_calories
                    ) {
                        isEnabled.push('enabled');
                    } else {
                        isEnabled.push('disabled');
                    }
                }
            }

            // handle time slots
            let isDifferentTimeSlotDifferent = [];
            const defaultSchedules = editItem?.ordering_modes[0]?.schedules;
            for (let index = 0; index < editItem.ordering_modes.length; index++) {
                const element = editItem.ordering_modes[index];
                // const isEqual = arrayEquals(defaultSchedules, element?.schedules);
                const sameSchedules = element?.schedules.filter(
                    (defaultSchedule) =>
                        !defaultSchedules.some(
                            (modeSchedules) =>
                                modeSchedules.start_time === defaultSchedule.start_time &&
                                modeSchedules.end_time === defaultSchedule.end_time
                        )
                );
                if (!sameSchedules?.length) {
                    isDifferentTimeSlotDifferent.push('disabled');
                } else {
                    isDifferentTimeSlotDifferent.push('enabled');
                }
            }

            const isDisabled = isEnabled?.find((enabled) => enabled === 'disabled') === 'disabled';
            editDefaultValue['itemImage'] = editItem.image;
            editDefaultValue['gst_slab'] = editItem.gst_slab;
            editDefaultValue['ordering_modes'] = editItem?.ordering_modes;
            for (const element of editItem?.ordering_modes) {
                editDefaultValue[
                    `${element.restaurant_ordering_mode_name}_${element.restaurant_ordering_mode_id}`
                ] = true;
                editDefaultValue[`quantity_${element.restaurant_ordering_mode_id}`] = element.quantity;
                editDefaultValue[`quantity_unit_id_${element.restaurant_ordering_mode_id}`] = element.quantity_unit_id;
                editDefaultValue[`serves_${element.restaurant_ordering_mode_id}`] = element.serves;
                editDefaultValue[`serves_unit_${element.restaurant_ordering_mode_id}`] = element.serves_unit;
            }
            editDefaultValue['quantity_unit_id'] = editItem?.ordering_modes?.[0]?.quantity_unit_id;
            editDefaultValue['price'] = editItem?.ordering_modes?.[0]?.price;
            editDefaultValue['packaging_charge'] = editItem?.ordering_modes?.[0]?.packaging_charge;
            editDefaultValue['gst_slab'] = editItem?.ordering_modes?.[0]?.gst_slab;
            editDefaultValue['compare_price'] = editItem?.ordering_modes?.[0]?.compare_price;
            editDefaultValue['quantity'] = editItem?.ordering_modes?.[0]?.quantity;

            editDefaultValue['allergens'] = editItem?.ordering_modes?.[0]?.allergens?.length
                ? editItem?.ordering_modes?.[0]?.allergens?.filter(
                      (allergen) => (allergen.label = allergen.internal_name)
                  )
                : [];
            editDefaultValue['calories'] = editItem?.ordering_modes?.[0]?.calories;
            editDefaultValue['serves'] = editItem?.ordering_modes?.[0]?.serves;
            editDefaultValue['serves_unit'] = editItem?.ordering_modes?.[0]?.serves_unit;
            // isDisabled

            if (isDifferentTimeSlotDifferent?.find((status) => status === 'enabled') !== 'enabled') {
                if (editItem.time_slot_setting === 'same_time_slot_for_all_days') {
                    editDefaultValue.time = formatedSscheduleMap['1'];
                    editDefaultValue['timer_for_item'] = 'same_time_for_all_days';
                } else if (editItem.time_slot_setting === 'different_time_slots_for_different_days') {
                    editDefaultValue.week = {
                        sunday: [],
                        monday: [],
                        tuesday: [],
                        wednesday: [],
                        thursday: [],
                        friday: [],
                        saturday: [],
                    };

                    for (const key in formatedSscheduleMap) {
                        if (key == 7) {
                            editDefaultValue.week.sunday = formatedSscheduleMap[key];
                        }
                        if (key == 1) {
                            editDefaultValue.week.monday = formatedSscheduleMap[key];
                        }
                        if (key == 2) {
                            editDefaultValue.week.tuesday = formatedSscheduleMap[key];
                        }
                        if (key == 3) {
                            editDefaultValue.week.wednesday = formatedSscheduleMap[key];
                        }
                        if (key == 4) {
                            editDefaultValue.week.thursday = formatedSscheduleMap[key];
                        }
                        if (key == 5) {
                            editDefaultValue.week.friday = formatedSscheduleMap[key];
                        }
                        if (key == 6) {
                            editDefaultValue.week.saturday = formatedSscheduleMap[key];
                        }
                    }
                    editDefaultValue['timer_for_item'] = 'different_time_for_different_days';
                } else {
                    editDefaultValue['timer_for_item'] = 'same_day_of_the_week';
                }
            }

            reset({
                ...editDefaultValue,
                is_enabled_same_price_details_for_all_ordering_modes: isDisabled ? true : false,
                is_enabled_same_time_slot_details_for_all_ordering_modes:
                    isDifferentTimeSlotDifferent?.find((status) => status === 'enabled') === 'enabled' ? true : false,
                badges: editItem?.badges,
                upselling_item: editItem?.upselling_item ? 'yes' : 'no',
                is_best_selling: editItem?.is_best_selling ? 'is_best_selling_yes' : 'is_best_selling_no',
                variantGroup: variantOptionArray.length
                    ? variantOptionArray
                    : [
                          {
                              title: '',
                              description: '',
                              price: editItem.price,
                              packaging_charge: editItem.packaging_charge,
                              compare_price: editItem.compare_price,
                              gst_slab: editItem.gst_slab,
                              food_type: editItem.food_type,
                          },
                          {
                              title: '',
                              description: '',
                              price: '',
                              packaging_charge: '',
                              compare_price: '',
                              gst_slab: '0',
                              food_type: 'vegetarian',
                          },
                      ],
                time: editDefaultValue.time ? editDefaultValue.time : [],
                week: editDefaultValue.week
                    ? editDefaultValue.week
                    : { sunday: [], monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [] },
            });
        } else {
            let editDefaultValue = {};
            availableLanguages.map((language) => {
                editDefaultValue[`title${language.language_id}`] = '';
                editDefaultValue[`description${language.language_id}`] = '';
            });
            editDefaultValue['internal_name'] = '';
            editDefaultValue['price'] = '';
            editDefaultValue['compare_price'] = '';
            editDefaultValue['packaging_charge'] = '';
            editDefaultValue['gst_slab'] = '0';
            editDefaultValue['itemImage'] = null;
            editDefaultValue['takeaway'] = '';
            editDefaultValue['dine_in'] = '';
            editDefaultValue['delivery'] = '';
            editDefaultValue['timer_for_item'] = 'same_day_of_the_week';
            editDefaultValue['food_type'] = 'vegetarian';
            editDefaultValue['upselling_item'] = false;
            editDefaultValue['is_best_selling'] = false;
            editDefaultValue['ordering_modes'] = [];

            dispatch({
                type: types.SET_ADDON_ITEM_EDIT_SELECTED_LIST,
                payload: {
                    selectedItemEditAddOnList: [],
                },
            });

            dispatch({
                type: types.SET_UPSELLING_ITEM_EDIT_SELECTED_LIST,
                payload: {
                    selectedItemEditUpsellingList: [],
                },
            });

            let defaultOrderingModes = [];

            if (orderingModes?.length) {
                defaultOrderingModes.push(orderingModes.filter((mode) => mode.status));
            }

            reset({
                ...editDefaultValue,
                is_enabled_same_price_details_for_all_ordering_modes: false,
                is_enabled_same_time_slot_details_for_all_ordering_modes: false,
                upselling_item: 'no',
                is_best_selling: 'is_best_selling_no',
                variantGroup: [
                    {
                        title: '',
                        description: '',
                        price: '',
                        packaging_charge: '',
                        compare_price: '',
                        gst_slab: '0',
                        food_type: 'vegetarian',
                    },
                    {
                        title: '',
                        description: '',
                        price: '',
                        packaging_charge: '',
                        compare_price: '',
                        gst_slab: '0',
                        food_type: 'vegetarian',
                    },
                ],
                time: [],
                week: { sunday: [], monday: [], tuesday: [], wednesday: [], thursday: [], friday: [], saturday: [] },
            });
        }
    }, [editItem, activeItemId, availableLanguages]);

    const dispatch = useDispatch();

    const [buttonLoader, setButtonLoader] = useState(false);

    const [stateUpdate, setStateUpdate] = useState(false);

    useEffect(() => {
        dispatch({
            type: types.SET_VARIANT_GROUP,
            payload: {
                variantGroup: [],
            },
        });
        dispatch({
            type: types.SET_VARIANT_ADDON_LINK,
            payload: {
                variantAddonLink: {},
            },
        });
        dispatch({
            type: types.SET_VARIANT_GROUP_TRANSLATIONS,
            payload: {
                variantGroupTranslations: {},
            },
        });
        dispatch({
            type: types.SET_VARIANT_MODAL_SAVED,
            payload: {
                variantModalSaved: false,
            },
        });

        return function cleanup() {
            dispatch({
                type: SET_BADGES_IDS,
                payload: [],
            });
            dispatch({
                type: SET_SAVED_VARIANT_GROUP,
                payload: {},
            });

            dispatch({
                type: UNLINK_BADGES_IDS,
                payload: [],
            });
            dispatch({
                type: types.REMOVE_UPSELLING_ITEM_EDIT_SELECTED_LIST,
                payload: {
                    removedItemEditUpsellingList: [],
                },
            });

            setShowError(false);
            setStateUpdate(false);
        };
    }, [editItem?.category_id, activeItemId, dispatch]);

    const recentlySavedData = useSelector((state) => state.menuReducer.savedVariantGroup);

    const variantDataFormat = () => {
        let variants = [];
        const afterVariantChanges = editItem?.variants?.[0]?.options?.filter((group) =>
            variantGroup?.some((option) => option.option_id === group.id)
        );

        // const otherWayAroundTest = variantGroup?.filter((option) =>
        //     editItem?.variants?.[0]?.options?.some((group) => option.option_id === group.id)
        // );

        // console.log(`otherWayAroundTest`, otherWayAroundTest);
        // console.log(`afterVariantChanges`, afterVariantChanges);
        // console.log(`variantGroupReducer`, variantGroupReducer);
        // console.log(`variantAddonLink`, variantAddonLink);
        // console.log(`variantGroup`, variantGroup);

        const variantOptions = variantGroupReducer?.length
            ? variantGroupReducer
            : afterVariantChanges?.length
            ? afterVariantChanges
            : [];

        if (variantModalSaved) {
            if (variantOptions?.length) {
                let optionArray = [];
                let translationObj = {};
                let description = {};
                availableLanguages.forEach((language) => {
                    translationObj[language.language_id] =
                        variantGroupTranslations[`variant_group_title-${language.language_id}`];
                    description[language.language_id] =
                        variantGroupTranslations[`variant_group_description-${language.language_id}`];
                });
                variants[0] = {
                    translations: { title: translationObj, description: description },
                    show_agmark: variantGroupTranslations.show_agmark ? variantGroupTranslations.show_agmark : 0,
                    variant_group_id: editItem?.variants?.[0]?.option_id
                        ? editItem?.variants?.[0]?.option_id
                        : editItem?.variants?.[0]?.id
                        ? editItem.variants[0].id
                        : variantGroupTranslations.variant_group_id,
                };

                variantOptions.forEach((variant, index) => {
                    let translationObject = {};

                    availableLanguages.forEach((language) => {
                        translationObject[language.language_id] = variant[`variant_title-${language.language_id}`];
                    });
                    if (variantAddonLink[variant.option_id]) {
                        let addon_group_ids = [];
                        variantAddonLink[variant.option_id].forEach((option) => {
                            addon_group_ids.push(option.addon_group_id);
                        });
                        variant.addon_group_ids = addon_group_ids;
                    } else if (!isEmpty(recentlySavedData)) {
                        const addon_group_ids = [];

                        if (variant?.addons?.length && !variant.addon_group_ids?.length) {
                            variant.addons.forEach((addon) => {
                                addon_group_ids.push(addon?.addon_group_id);
                            });
                        }

                        variant.addon_group_ids = addon_group_ids;
                    } else if (
                        editItem?.variants?.[0]?.options?.length &&
                        editItem?.variants[0]?.options[index]?.addons?.length
                    ) {
                        let addon_group_ids = [];

                        editItem.variants[0].options[index].addons.forEach((addon) => {
                            addon_group_ids.push(addon.addon_group_id);
                        });
                        variant.addon_group_ids = addon_group_ids;

                        // variant.addon_group_ids = editItem.variants[0].options
                    }

                    optionArray.push({
                        ...variant,
                        image_id: variant.image_url ? variant.image_url.id : null,
                        translations: !isEmpty(variant?.translations)
                            ? variant?.translations
                            : { title: translationObject },
                    });
                });
                variants[0].options = optionArray;
            }
        } else if (editItem?.variants?.length) {
            editItem.variants[0].variant_group_id = editItem.variants[0].id;
            if (!isEmpty(variantAddonLink)) {
                editItem.variants[0].options.map((option) => {
                    option.id = option.option_id;
                    let addon_group_ids = [];
                    variantAddonLink[option.id].map((option) => {
                        addon_group_ids.push(option.addon_group_id);
                    });

                    option.addon_group_ids = addon_group_ids;
                });

                variants = [...editItem.variants];
            } else {
                editItem.variants[0].options.map((option) => {
                    let addon_group_ids = [];
                    option.addons.map((addon) => {
                        addon_group_ids.push(addon.addon_group_id);
                    });

                    option.addon_group_ids = addon_group_ids;
                });

                variants = [...editItem.variants];
            }
        }

        return variants;
    };

    const editItemId = async (
        data,
        addOnArray,
        translationObject,
        variantsStructuredData,
        upSellingItems,
        removedItemIds
    ) => {
        let res;
        try {
            res = await API.put(`/restaurants/:restaurantId/categories/${activeCategoryId}/items/${editItem.item_id}`, {
                schedules: data.time_slot_setting !== 'no_time_slots' ? data.schedules : null,
                time_slot_setting: data.time_slot_setting,
                internal_name: data.internal_name,
                hidden: 0,
                show_agmark: 1,
                upselling_item_ids: upSellingItems,
                upselling_item: data?.upselling_item ? data.upselling_item : false,
                is_best_selling: data?.is_best_selling ? data.is_best_selling : false,
                image_id: data.itemImage ? data.itemImage.id : null,
                food_type: data.food_type,
                addon_groups: addOnArray,
                translations: translationObject,
                variants: variantsStructuredData,
                ordering_modes: data.ordering_modes,
                delete_ordering_modes: data?.delete_ordering_modes ? data.delete_ordering_modes : [],
                badges_ids: badges_ids?.length ? badges_ids : [],
                delete_upselling_item_ids: removedItemIds?.length ? removedItemIds : [],
                delete_badge_ids: unlink_badges_ids?.length ? unlink_badges_ids : [],
            });
            fetchUpadtedCategoryItems();
        } catch (error) {
            setButtonLoader(false);
            res = error;
        }
        if (res.success) {
            dispatch({
                type: types.SET_CATEGORY_STATE,
                payload: {
                    categoryState: {},
                },
            });
            setErrorMessage('');
            toastSuccessMessage('Item successfully edited', dispatch);
            setButtonLoader(false);
        } else {
            setErrorMessage(res.errors);
            toastsErrorMessage('Failed to edit an item', dispatch);
            setButtonLoader(false);
        }
    };

    const addItemId = async (
        data,
        addOnArray,
        translationObject,
        variantsStructuredData,
        upSellingItems,
        removedItemIds
    ) => {
        let res;
        try {
            res = await API.post(`/restaurants/:restaurantId/categories/${activeCategoryId}/items`, {
                schedules: data.time_slot_setting !== 'no_time_slots' ? data.schedules : null,
                time_slot_setting: data.time_slot_setting,
                internal_name: data.internal_name,
                hidden: 0,
                show_agmark: 1,
                upselling_item: data.upselling_item,
                is_best_selling: data.is_best_selling,
                upselling_item_ids: upSellingItems,
                image_id: data.itemImage ? data.itemImage.id : null,
                food_type: data.food_type,
                addon_groups: addOnArray,
                translations: translationObject,
                variants: variantsStructuredData,
                ordering_modes: data.ordering_modes,
                delete_ordering_modes: data?.delete_ordering_modes ? data.delete_ordering_modes : [],
                badges_ids: badges_ids?.length ? badges_ids : [],
                delete_upselling_item_ids: removedItemIds?.length ? removedItemIds : [],
                delete_badge_ids: unlink_badges_ids?.length ? unlink_badges_ids : [],
            });
            fetchUpadtedCategoryItems();
        } catch (error) {
            setButtonLoader(false);
            res = error;
        }
        if (res.success) {
            dispatch({
                type: types.SET_CATEGORY_STATE,
                payload: {
                    categoryState: {},
                },
            });
            setErrorMessage('');
            toastSuccessMessage('Item created successfully', dispatch);
            setButtonLoader(false);
        } else {
            setErrorMessage(res.errors);
            toastsErrorMessage('Failed to create an item', dispatch);
            setButtonLoader(false);
        }
    };

    const fetchUpadtedCategoryItems = useCallback(async () => {
        await fetchCategoryItem(activeCategoryId, languageId).then((response) => {
            if (response.success) {
                dispatch({
                    type: SET_CATALOGUE_ITEM_LIST,
                    payload: {
                        [`item_${activeCategoryId}`]: response.items,
                    },
                });
            }
        });
    }, [activeCategoryId, dispatch, languageId]);

    const EditItem = async (data) => {
        if (!mainSubmitClicked.current) return;
        setButtonLoader(true);

        if (activeItemId === 'newId') {
            if (!data.is_enabled_same_price_details_for_all_ordering_modes) {
                // Same Price For Every Ordering Mode
                data?.ordering_modes.forEach((orderMode) => {
                    orderMode.price = data.price;
                    orderMode.compare_price = data.compare_price;
                    orderMode.gst_slab = parseFloat(data.gst_slab);
                    orderMode.packaging_charge = data.packaging_charge;
                    orderMode.quantity_unit_id = data[`quantity_unit_id`];
                    orderMode.time_slot_setting = data[`time_slot_setting`];
                    orderMode.quantity = data[`quantity`];
                    orderMode.serves = data[`serves`];
                    orderMode.serves_unit = data[`serves_unit`];
                    orderMode[`restaurant_ordering_mode_id`] = orderMode?.id
                        ? orderMode.id
                        : orderMode?.restaurant_ordering_mode_id
                        ? orderMode.restaurant_ordering_mode_id
                        : null;
                    orderMode.allergens = data.allergens;
                    orderMode.calories = data.calories;
                    timeSlotData(data, orderMode);
                });
            } else {
                // Handle Different Prices & Time Slots for Multiple Ordering Modes
                data?.ordering_modes.forEach((orderMode) => {
                    orderMode.price = data[`price_${orderMode.restaurant_ordering_mode_id}`];
                    orderMode.compare_price = data[`compare_price_${orderMode.restaurant_ordering_mode_id}`]
                        ? parseFloat(data[`compare_price_${orderMode.restaurant_ordering_mode_id}`])
                        : 0;
                    orderMode.gst_slab = data[`gst_slab_${orderMode.restaurant_ordering_mode_id}`]
                        ? data[`gst_slab_${orderMode.restaurant_ordering_mode_id}`]
                        : 0;
                    orderMode.packaging_charge = data[`packaging_charge_${orderMode.restaurant_ordering_mode_id}`]
                        ? data[`packaging_charge_${orderMode.restaurant_ordering_mode_id}`]
                        : 0;
                    orderMode.quantity_unit_id = data[`quantity_unit_id_${orderMode.restaurant_ordering_mode_id}`];
                    orderMode.quantity = data[`quantity_${orderMode.restaurant_ordering_mode_id}`];
                    orderMode.serves = data[`serves_${orderMode.restaurant_ordering_mode_id}`];
                    orderMode.serves_unit = data[`serves_unit_${orderMode.restaurant_ordering_mode_id}`];

                    // multipleTimings(orderMode, data);
                });
            }
            if (data.is_enabled_same_time_slot_details_for_all_ordering_modes) {
                data?.ordering_modes.forEach((orderMode) => {
                    multipleTimings(orderMode, data);
                });
            } else {
                data?.ordering_modes.forEach((orderMode) => {
                    timeSlotData(data, orderMode);
                });
            }
        } else if (data?.variants?.[0]?.options?.length) {
            // Handle Variants Pricing
            const variant_option = data.variants[0].options[0];
            data?.ordering_modes.forEach((orderMode) => {
                orderMode.price = variant_option[`price`];
                orderMode.compare_price = variant_option[`compare_price`] ? variant_option[`compare_price`] : 0;
                orderMode.gst_slab = variant_option[`gst_slab`] ? parseFloat(variant_option[`gst_slab`]) : 0;
                orderMode.packaging_charge = variant_option[`packaging_charge`]
                    ? variant_option[`packaging_charge`]
                    : 0;
                // orderMode[`restaurant_ordering_mode_id`] = orderMode.restaurant_ordering_mode_id;
                // FOR VARIANTS
                orderMode.quantity_unit_id =
                    variant_option[`quantity_unit_id_${orderMode.restaurant_ordering_mode_id}`];
                orderMode.quantity = variant_option[`quantity_${orderMode.restaurant_ordering_mode_id}`];
                orderMode.serves = variant_option[`serves_${orderMode.restaurant_ordering_mode_id}`];
                orderMode.serves_unit = variant_option[`serves_unit_${orderMode.restaurant_ordering_mode_id}`];
                orderMode[`restaurant_ordering_mode_id`] = orderMode?.id
                    ? orderMode.id
                    : orderMode?.restaurant_ordering_mode_id
                    ? orderMode.restaurant_ordering_mode_id
                    : null;
                if (!data.is_enabled_same_time_slot_details_for_all_ordering_modes) {
                    timeSlotData(data, orderMode);
                } else {
                    multipleTimings(orderMode, data);
                }
            });
        } else if (
            !data.is_enabled_same_price_details_for_all_ordering_modes &&
            !data.is_enabled_same_time_slot_details_for_all_ordering_modes
        ) {
            // Same Price For Every Ordering Mode
            data?.ordering_modes.forEach((orderMode) => {
                orderMode.price = data.price;
                orderMode.compare_price = data.compare_price;
                orderMode.gst_slab = parseFloat(data.gst_slab);
                orderMode.packaging_charge = data.packaging_charge;
                orderMode.quantity_unit_id = data[`quantity_unit_id`];
                orderMode.quantity = data[`quantity`];
                orderMode.serves = data[`serves`];
                orderMode.serves_unit = data[`serves_unit`];
                orderMode[`restaurant_ordering_mode_id`] = orderMode?.id
                    ? orderMode.id
                    : orderMode?.restaurant_ordering_mode_id
                    ? orderMode.restaurant_ordering_mode_id
                    : null;
                orderMode.allergens = data.allergens;
                orderMode.calories = data.calories;
                timeSlotData(data, orderMode);
            });
        } else if (!data.is_enabled_same_time_slot_details_for_all_ordering_modes) {
            data?.ordering_modes.forEach((orderMode) => {
                orderMode[`restaurant_ordering_mode_id`] = orderMode?.id
                    ? orderMode.id
                    : orderMode?.restaurant_ordering_mode_id
                    ? orderMode.restaurant_ordering_mode_id
                    : null;
                timeSlotData(data, orderMode);
            });
        } else if (!data.is_enabled_same_price_details_for_all_ordering_modes) {
            // Same Price For Every Ordering Mode
            data?.ordering_modes.forEach((orderMode) => {
                orderMode.price = data.price;
                orderMode.compare_price = data.compare_price;
                orderMode.gst_slab = parseFloat(data.gst_slab);
                orderMode.packaging_charge = data.packaging_charge;
                orderMode.quantity_unit_id = data[`quantity_unit_id`];
                orderMode.quantity = data[`quantity`];
                orderMode.serves = data[`serves`];
                orderMode.serves_unit = data[`serves_unit`];
                orderMode[`restaurant_ordering_mode_id`] = orderMode?.id
                    ? orderMode.id
                    : orderMode?.restaurant_ordering_mode_id
                    ? orderMode.restaurant_ordering_mode_id
                    : null;
                orderMode.allergens = data.allergens;
                orderMode.calories = data.calories;
                timeSlotData(data, orderMode);
            });
        } else {
            // Handle Different Prices & Time Slots for Multiple Ordering Modes
            data?.ordering_modes.forEach((orderMode) => {
                orderMode.price = data[`price_${orderMode.restaurant_ordering_mode_id}`];
                orderMode.compare_price = data[`compare_price_${orderMode.restaurant_ordering_mode_id}`]
                    ? data[`compare_price_${orderMode.restaurant_ordering_mode_id}`]
                    : 0;
                orderMode.gst_slab = data[`gst_slab_${orderMode.restaurant_ordering_mode_id}`]
                    ? parseFloat(data[`gst_slab_${orderMode.restaurant_ordering_mode_id}`])
                    : 0;
                orderMode.packaging_charge = data[`packaging_charge_${orderMode.restaurant_ordering_mode_id}`]
                    ? data[`packaging_charge_${orderMode.restaurant_ordering_mode_id}`]
                    : 0;
                orderMode.quantity_unit_id = data[`quantity_unit_id_${orderMode.restaurant_ordering_mode_id}`];
                orderMode.quantity = data[`quantity_${orderMode.restaurant_ordering_mode_id}`];
                orderMode.serves = data[`serves_${orderMode.restaurant_ordering_mode_id}`];
                orderMode.serves_unit = data[`serves_unit_${orderMode.restaurant_ordering_mode_id}`];
                orderMode[`restaurant_ordering_mode_id`] = orderMode?.id
                    ? orderMode.id
                    : orderMode?.restaurant_ordering_mode_id
                    ? orderMode.restaurant_ordering_mode_id
                    : null;

                multipleTimings(orderMode, data);
            });
        }

        // different timeslots issue fix

        if (data.is_enabled_same_time_slot_details_for_all_ordering_modes) {
            data?.ordering_modes.forEach((orderMode) => {
                multipleTimings(orderMode, data);
            });
        }

        await timeSlotData(data);
        let variantsStructuredData = variantDataFormat();

        // variantsStructuredData stores all the functions/proprties about variants

        let titles = {};
        let descriptions = {};

        availableLanguages.forEach((language) => {
            titles[language.language_id] = data[`title${language.language_id}`];
            descriptions[language.language_id] = data[`description${language.language_id}`];
        });

        let translationObject = {
            title: titles,
            description: descriptions,
        };
        let addOnArray = [];
        let upSellingItems = [];

        selectedItemEditAddOnList?.forEach((addon, index) => {
            addOnArray.push({
                addon_group_id: addon.addon_group_id,
                display_sequence: index + 1,
            });
        });
        selectedItemEditUpsellingList?.forEach((upSellingItem) => {
            upSellingItems.push(upSellingItem?.item_id ? upSellingItem.item_id : upSellingItem);
        });
        setStateUpdate(false);

        let removedItemIds = [];

        if (removedItemEditUpsellingList?.length && editItem?.upselling_items?.length) {
            for (const upselling_item of editItem.upselling_items) {
                for (const removedItemId of removedItemEditUpsellingList) {
                    if (upselling_item.item_id === removedItemId) {
                        removedItemIds.push(removedItemId);
                    }
                }
            }
        } else if (removedItemEditUpsellingList?.length) {
            for (const removedItemId of removedItemEditUpsellingList) {
                removedItemIds.push(removedItemId);
            }
        }

        if (data.upselling_item === 'no') {
            data.upselling_item = false;
        } else {
            data.upselling_item = true;
        }
        if (data.is_best_selling === 'is_best_selling_no') {
            data.is_best_selling = false;
        } else {
            data.is_best_selling = true;
        }

        if (activeItemId !== 'newId') {
            editItemId(data, addOnArray, translationObject, variantsStructuredData, upSellingItems, removedItemIds);
        } else {
            addItemId(data, addOnArray, translationObject, variantsStructuredData, upSellingItems, removedItemIds);
        }

        mainSubmitClicked.current = false;
    };

    const variantConditionForPrice = () => {
        if (variantModalSaved) {
            return !variantGroupReducer.length;
        } else {
            return !(editItem.variants && editItem.variants.length);
        }
    };

    return (
        <>
            <MappedItemModal item={editItem} setIsMapItemModal={setIsMapItemModal} mapItemModal={mapItemModal} />
            {isItemDetailsLoading ? (
                <>
                    <CataloguePanelHeader panelTitle='Item Details' />
                    <EuiFlexGroup style={{ height: screenHeight }} direction='column' gutterSize='xs'>
                        {loadingArray.map((lines, index) => {
                            return (
                                <EuiFlexItem key={index.toString()}>
                                    <EuiPanel style={{ margin: 10 }}>
                                        <EuiLoadingContent lines={lines} />
                                    </EuiPanel>
                                    <EuiHorizontalRule />
                                </EuiFlexItem>
                            );
                        })}
                    </EuiFlexGroup>
                </>
            ) : (
                <>
                    <CataloguePanelHeader panelTitle='Item Details' />

                    <EuiFlexItem className='itemDetailStyle scrollbar-sm'>
                        <EuiSpacer size='s' />
                        <FormProvider {...methods}>
                            <EuiForm component='form' onSubmit={handleSubmit(EditItem)}>
                                {!isEmpty(editItem) ? (
                                    <EuiFlexGroup style={{ height: screenHeight }} direction='column'>
                                        <EuiFlexItem>
                                            <ItemBasicDetails variantConditionForPrice={variantConditionForPrice} />

                                            <EuiHorizontalRule />

                                            <ItemExpose />

                                            <EuiHorizontalRule />

                                            <ItemPricing variantConditionForPrice={variantConditionForPrice} />

                                            <EuiHorizontalRule />

                                            <ItemBadges />

                                            <EuiHorizontalRule />

                                            <ItemAddonGroups variantConditionForPrice={variantConditionForPrice} />

                                            <EuiHorizontalRule />

                                            <ItemImage />

                                            {!isOutletMenu ? (
                                                <>
                                                    <EuiHorizontalRule />
                                                    <ItemTimings stateUpdate={stateUpdate} />
                                                </>
                                            ) : null}
                                            <EuiHorizontalRule />

                                            <ItemUpSellingLinks />

                                            <EuiHorizontalRule />

                                            <ItemUpsellCart />

                                            <EuiHorizontalRule />

                                            <ItemBestSellCategory methods={methods} />

                                            <EuiHorizontalRule />

                                            <ItemFormErrors
                                                showError={showError}
                                                setShowError={setShowError}
                                                errorMessage={errorMessage}
                                            />

                                            <ItemDetailsActions
                                                buttonLoader={buttonLoader}
                                                setErrorMessage={setErrorMessage}
                                                setShowError={setShowError}
                                                mainSubmitClicked={mainSubmitClicked}
                                            />
                                        </EuiFlexItem>
                                        <EuiSpacer size='s' />
                                    </EuiFlexGroup>
                                ) : (
                                    <EuiCard title='No Record Found' style={{ margin: 10 }} />
                                )}
                            </EuiForm>
                        </FormProvider>
                    </EuiFlexItem>
                </>
            )}
        </>
    );
};

export default React.memo(ItemDetailsEditor);
